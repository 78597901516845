import { apiEndpoint } from "./config";

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cloud_logic_custom: [
    {
      name: "herokuapi",
      endpoint: apiEndpoint,
      region: "eu-central-1",
    },
  ],
};

export default awsmobile;
